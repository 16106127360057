.wrapper-certificate-modal{
  max-width: 637px;
  width: 100%;
  .card{
    margin: 0 auto;
    padding: 20px;
    .card-header-certificate{
      border-bottom: 1px solid  #2F2F3B45;
      padding-bottom: 18px;

      .card-header-left{
        font-weight: 500;
        font-size: 20px;
        line-height: 28.8px;
        color: #2F2F3B;
      }
      .card-header-right{
        cursor: pointer;
      }
    }
    .form-group{
      border-bottom: 1px solid  #2F2F3B45;
      padding-bottom: 18px;
    }
    .btn-wrapper{
      gap: 20px;
      .btn-left{
        color: #7366FF;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        text-decoration: underline;
        text-decoration-style: solid;
        text-decoration-thickness: Auto;
        align-items: center;
        text-align: center;
        cursor: pointer;
        display: flex;
      }
    }
  }
}