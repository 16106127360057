
@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.wrapper-abs{
    position: relative;
}
.sign-question {
  z-index: 1;
  position: fixed;
  bottom: 55px;
  right: 150px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  /* Цвет кнопки можно изменить */
  color: rgb(65, 65, 65);
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.popover {
  position: absolute;
  top: -300px;
  right: 100px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 999;
  opacity: 1;
  transition: all 0.5s;
}

.floating-action-button {
  z-index: 1;
  position: fixed;
  bottom: 55px;
  right: 150px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #007bff;
  /* Цвет кнопки можно изменить */
  color: white;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
    /* Изменение цвета при наведении */
  }

  &:focus {
    outline: none;
    /* Убираем рамку при фокусе */
  }
}

.floating-action-button-bg {
  position: fixed;
  bottom: 55px;
  right: 150px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #007bff;
  /* Цвет кнопки можно изменить */
  color: white;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  animation: wave 5s ease-in-out infinite;

  &:hover {
    background-color: #0056b3;
    /* Изменение цвета при наведении */
  }

  &:focus {
    outline: none;
    /* Убираем рамку при фокусе */
  }
}
@media (max-width: 800px) {
  .popover {
    position: absolute;
    top: -350px;
    right: 195px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 999;
    opacity: 1;
    transition: all 0.5s;
    }
}
