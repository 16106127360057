.my-active-wrapper{
  min-height: 475px;
  .card{
    .card-head{
      .header-top-switch{
        .wrapper-switch{
          background: #F5F6F9;
          border-radius: 2px;
          padding: 4px;
          .btn-switch{
            transition: all .3s;
          }
          .btn-check:checked + .btn-switch {
            border-radius: 2px;
            background-color: $white;
            color: $purple;
            transition: all .3s;
          }
        }
      }
    }
    .wrapper-slider{
      overflow: hidden;
      position: relative;
      .slide.one{
        position: absolute;
        transform: translateX(-100%);
        transition: all .5s;
      }
      .slide.two{
        position: absolute;
        transform: translateX(100%);
        transition: all .5s;
      }
      .slide.active{
        transform: translateX(0);
        transition: all .5s;
      }
      .slide{
        .item-slide{
          transition: all .3s;
          border-radius: 5px;
          .item-slide-value{
            color: $green-text;
            font-size: 12px;
            font-weight: 600;
            line-height: 28.8px;
            text-align: left;
          }
          &:hover{
            background: #f6f6f6;
            transform: translateY(-5px);
            transition: all .3s;
          }
          .item-slide-value{
            .icon-eye {
              width: 30px !important;
              height: 30px !important;
            }
          }
        }
      }
    }
  }
}

.wrapper-graph{
  .card{
    .card-body {
      .btn-learn-more{
        max-width: 150px;
      }
      .btn-learn-more:hover{
        color: white;
        background-color: var(--theme-deafult) !important;
        border-color: var(--theme-deafult) !important;
      }
    }
  }
}

.card-text-sub{
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #52526CBF;
}

.card.furure-in-hands{
  overflow: hidden;
  position: relative;
  .wrapper-furure-in-hands{
    img{
      position: absolute;
      right: 50%;
      top: 0;
      transform: translate(50%, -40%);
      z-index: 1;
      width: 100%;
      @media (max-width: 510px) {
        transform: translate(50%, 0%);
      }
    }
    p{
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      color: #52526CBF;
      max-width: 430px;
    }
  }
  .background-city{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    height: 100%;
  }
}

.market-card{
  img{
    height: 75px;
    width: auto;
  }
}

.wrapper-cover{
  position: relative;
  overflow: hidden;
  max-height: 480px;
  border-radius: 10px 10px 0 0;
  background: #fff;
  @media (max-width: 600px) {
    width: 100% !important;
    height: 200px !important;
  }
  .btn-cover{
    position: absolute;
    color: #fff;
    background: #2F2F3B8C;
    top: 10%;
    right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    padding: 5px 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s;
    label{
      cursor: pointer;
    }
    &:hover{
      transform: translateX(-3px);
    }
  }
}
.edit{
  width: 18px;
  height: 18px;
}
.circle-edits{
  width: 30px;
  height: 30px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
  top: -7px;
  left: 50px;
  cursor: pointer;
  transition: all .3s;
}
.circle-edits:hover{
  transform: translateY(-3px);
  transition: all .3s;
}
.wrapper-cover-image{
  width: 100% !important;
  height: auto !important;
  @media (max-width: 600px) {
    width: auto !important;
    height: 100% !important;
  }
}
.wrapper-cover-image.adapt{
  width: 100% !important;
  height: auto !important;
  @media (max-width: 600px) {
    width: 100% !important;
    height: auto !important;
  }
}
.hovercard{
  border-radius: 0 0 10px 10px;
}

/*==== alert ====*/
.alert-profile{
  display: none;
}
.alert-profile.error {
  display: block;
  position: fixed;
  right: 0;
  top: 50px;
  background: #ff5e5e;
  padding: 5px 5px 5px 20px;
  border-radius: 5px 0 0 5px;
  color: #fff;
  animation: alerte 5s ease 0s 1 normal forwards;
  z-index: 999;
  @keyframes alerte {
    0% {
      transform: translateX(100%);
    }

    30% {
      transform: translateX(0%);
    }

    70% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(100%);
    }
  }
}

/*==== loading ====*/

/* HTML: <div class="loader"></div> */

.wrapper-loader{
  height: 200px;
}
.wrapper-loader.avatar-loader{
  height: auto;
}

.loader-cover {
  width: 55px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #373737;
  animation:
          l20-1 0.8s infinite linear alternate,
          l20-2 1.6s infinite linear;
}
@keyframes l20-1{
  0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
  12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
  25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
  50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
  100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}

.loader-button {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid #ffffff;
  animation:
          l20-1 0.8s infinite linear alternate,
          l20-2 1.6s infinite linear;
}
@keyframes l20-1{
  0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
  12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
  25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
  50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
  100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}