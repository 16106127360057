
.event_topic {
  width: 100%;
  word-break: break-all;
}

.detailEventImg {
  width: 100%;
}

.detailEventImg img {
}
