.event {
  &__image {
    width: 100%;
    background-position: center;
    background-size: 100vw;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-size: 110vw;
    }
  }
}

.event-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px !important;
  p {
    margin-bottom: 0;
    color: $dark-gray;
  }

  a {
    margin-top: 10px;
    color: #ffffff !important;
  }

  button {
    align-self: flex-end;
    border: none;
    background-color: transparent;
    color: $primary-color;
    cursor: pointer;
  }
}

.btn-event {
  margin-bottom: 20px;
}

.form-textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
