.wrapper-list-projects-item{
  border-radius: 15px;
  max-width: 583px;
  .wrapper-list-projects-item-inner{
    .item-description{
      padding: 30px;

      .item-description-low-title{
        color: #52526CBF;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
      .item-description-title{
        color: #2F2F3B;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
      .item-description-desc{
        color: #212529BF;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
      .item-under{
        .item-under-left{
          color: #7366FF;
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

        }
        .item-under-right{
          cursor: pointer;
          color: #FFFFFF;
          background: linear-gradient(90.42deg, #6C5CF7 0%, #563EE0 100%);
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          padding: 10px 25px;
          align-items: center;
          border-radius: 6px;
        }
      }
    }
    .item-img{
      img{
        border-radius: 15px 15px 0 0;
        max-width: 583px;
        width: 100%;
        max-height: 649px;
        height: 100%;
      }
    }
  }
}