.wrapper-courses {
  margin: 0 auto;
  width: min(100%, 1500px);

  .onhover-dropdowns {
    cursor: pointer;
    position: relative;
    .onhover-show-divs{
      visibility: hidden;
      bottom: -330px;
      right: -10px;
      position: absolute;
      z-index: 1000;
      background-color: #fff;
      transition: all linear 0.3s;
      border-radius: 10px;
      opacity: 0;
      box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
      height: auto;
      width: 100%;
      min-width: 350px;
      border-radius: 8px;
      .onhover-header{
        background: #EAE8FF;
        color: $purple;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }
      li{
        color: $second-text;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;

    }
    &:hover {
      .onhover-show-divs {
        position: absolute;
        opacity: 1;
        transform: translate(0%, 10%);
        visibility: visible;
        border-radius: 5px;
        z-index: 9;
        overflow: hidden;
      }
    }
  }

  .card-body-custom {
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      display: none;
    }

    svg{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 190px;
      height: 190px;
    }
    
    .card-custom {
      width: min( 100%, 340px );
      margin-bottom: 30px;
      border: none;
      transition: all 0.3s ease;
      letter-spacing: 0.5px;
      border-radius: 15px;
      box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
      position: relative;
      flex-shrink: 0; /* Prevent items from shrinking */
    }
    .btn-disable{
      background: #F8F8F8;
      color: #212529BF;

    }
  }
  .modal-wrapper {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: all .3s;
    .modal-content {
      background-color: white;
      max-width: 523px;
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      z-index: 2;
      transform: translateY(-30%);
      transition: all .3s;
    }
  }
  .modal-wrapper.active{
    visibility: visible;
    opacity: 1;
    transition: all .3s;
    .modal-content {
      background-color: white;
      max-width: 523px;
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      z-index: 2;
      transform: translateY(0%);
      transition: all .3s;
    }
  }

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .text-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
  }

  .wrapper-list{
    .list-item{
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
      color: $black-text;

    }
  }
}