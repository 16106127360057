.card-item {
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 15px;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  position: relative;
  background: #ffff;
}
.card-item:hover{
  transform: translateY(-3px);
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;
  .onhover-show-divs{
    visibility: hidden;
    position: absolute;
    top: -20px;
    right: 30px;
    z-index: 1000;
    background-color: #fff;
    transition: all linear 0.3s;
    border-radius: 10px;
    opacity: 0;
    box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
    width: auto;
    min-width: 330px;
    .onhover-header{
      background: #EAE8FF;
      color: $purple;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
    li{
      color: $second-text;
      font-size: 14px;
      font-weight: 400;
    }
  }
  p{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

  }
  &:hover {
    .onhover-show-divs {
      visibility: visible;
      opacity: 1;
      transform: translateY(-5px);
    }
  }
}

.onhover-dropdowns {
  cursor: pointer;
  position: relative;
  .onhover-show-divs{
    visibility: hidden;
    bottom: -10px;
    right: 20px;
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    transition: all linear 0.3s;
    border-radius: 10px;
    opacity: 0;
    box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
    min-height: 80px;
    height: auto;
    width: auto;
    min-width: 300px;
  }
  &:hover {
    .onhover-show-divs {
      position: absolute;
      opacity: 1;
      transform: translateX(-10px);
      visibility: visible;
      border-radius: 5px;
      z-index: 9;
      overflow: hidden;
    }
  }
}