.wrapper-parents{
  width: min(100%, 1400px);
  
  .card{
    .text-purple{
      color: #7366ff;
    }
    .image-rantie{
      width: 200px;
      height: 200px;
      flex-shrink: 0;
      position: relative;

      @media (max-width: 768px) {
        width: 100%;
        max-width: 300px;
        height: auto;
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      img.block{
        opacity: 0;
        position: relative;
      }
    }

    .image-rantie-strategic-partners {
      width: 200px;
      height: 200px;
      flex-shrink: 0;
      position: relative;

      @media (max-width: 768px) {
        width: 100%;
        max-width: 300px;
        height: auto;
      }

      img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      img.block{
        opacity: 0;
        position: relative;
      }
    }

    .content-partner{
      min-height: 150px;
      h5{
        border-bottom: 1px solid #C7C8C9;
      }
      p{
        font-size: clamp(12px , 1vw + 1vw, 14px);
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.699999988079071px;
        .text-purple{
          color: $purple
        }
      }
      .wrapper-parents-href{
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        p{
          margin: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.5px;
          color: #52526CBF;
          white-space: normal; /* Позволяем перенос строк в параграфах */
        }
      }
    }
  }
}