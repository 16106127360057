.wrapper-cover-slider{
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
  .wrapper-carousel{
    overflow: hidden;
    border-radius: 15px 15px 0 0;
    .wrapper-carousel-item{
      flex: 0 0 auto;
      max-width: 1400px;
      width: 100%;
      transition: all 1.5s;
      img{
        max-height: 600px;
        max-width: 1400px;
        width: 100%;
        height: auto;
      }
    }
  }
}

.user-profile{
  max-width: 1400px;
  margin: 0 auto;
}

