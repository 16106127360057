.wrapper-application-page{
  width: min( 100% , 1540px );
  margin: 0 auto;
  padding: 40px 0;
  animation: fadeInSlideUp 0.5s ease forwards;

  .item-phone{
    display: flex;
    align-items: center;
    height: clamp(523px, 100vh, 1046px);;
    background: url("@/assets/images/documentation/Pic-main.png") no-repeat center;

    @media (max-width: 1300px) {
      background: url("@/assets/images/documentation/Pic-main.png") no-repeat;
    }
    
    @media (max-width: 520px) {
      background: url("@/assets/images/documentation/phone-model.png") no-repeat;
      background-position: center;
      background-attachment: fixed;
      background-size: cover;
    }
    .content-area{
      width: min( 100%, 665px );
      .description-text{
        font-size: clamp(12px, 3vw + 1rem, 24px);
        font-weight: 400;
        line-height: 1;
        color: #52526CBF;
      }
      .btn-primary{
        font-size: clamp(12px, 2vw + 1rem, 20px);
        font-weight: 400;
        padding: 16px 24px;
      }
      .block-img{
        height: 0;

        @media (max-width: 520px) {
          margin-top: 100px;
          height: 490px;
        }
      }
    }
  }
  .item-wrappers-application{
    padding: 40px;

    @media (max-width: 720px) {
      padding: 20px;
    }
    .download-text{
      font-size: clamp(16px, 1vw + 1rem, 24px);
      font-weight: 400;
      line-height: 19.2px;
      color: #52526CBF;
      .download-text-title{
        font-size: clamp(24px, 3vw + 1rem, 80px);
        font-weight: 600;
        line-height: 88px;
        @media (max-width: 720px) {
          line-height: 1.2;
        }
      }
      .download-text-description-text{
        font-size: clamp(12px, 1vw + 1rem, 24px);
        font-weight: 400;
        line-height: 1;
        color: #52526CBF;
      }
      .btn-primary{
        font-size: clamp(12px, 1vw + 1vw, 20px);
        font-weight: 400;
        padding: 16px 24px;

      }
    }
    .download-img{
      img{
        transition: all .3s;
        &:hover{
          transform: scale(1.1)rotate(-5deg);
        }
      }
    }
    .app-wrapper-content{
      .card-app-content{
        background: $purple;
        border-radius: 16px;
        cursor: pointer;
        transition: all .3s;
        &:hover{
          transform: translateY( -5px );
        }

        .app-wrapper-content-title{
          font-size: clamp(24px, 1vw + 1rem, 48px);
          font-weight: 600;
          line-height: 52.8px;
          color: $white;
          margin-bottom: 2px;
        }
        .app-wrapper-content-description{
          font-size: clamp(16px, 1vw + 1vw, 24px);
          font-weight: 400;
          line-height: 31.2px;
          color: $white;
        }
      }
    }
    .app-wrapper-content-bg{
      padding: 70px 0;
      background: url("@/assets/images/documentation/app-page/background-phone-simple-start.png") no-repeat center;
      background-size: 800px auto;
      @media (max-width: 1575px) {
        background-size: 700px auto;
      }
      @media (max-width: 1200px) {
        background: none;
        padding: 40px 0;
      }
      .card-bg-top-icon{
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        font-size: clamp(16px, 2vw + 1rem, 32px);
        font-weight: 600;
        line-height: 41.6px;

        @media (max-width: 720px) {
          height: 40px;
          width: 40px;
        }
      }
      .card-bg{
        border-radius: 16px;
        color: $white;
        cursor: pointer;
        transition: all .3s;
        &:hover{
          transform: translateY( -5px );
        }
        &.orange-bg{
          background: #FFA132;
          .card-bg-top-icon{
            color: #FFA132;
          }
        }
        &.pink-bg{
          background: #FF3868;
          .card-bg-top-icon{
            color: #FF3868;
          }
        }
        &.green-bg{
          background: #54BA4A;
          .card-bg-top-icon{
            color: #54BA4A;
          }
        }
        .card-bg-top-title{
          margin-left: 10px;
          font-size: clamp(16px, 2vw + 1rem, 24px);;
          font-weight: 600;
          line-height: 35.2px;
        }
      }
    }
    &.card{
      background: #2F2F3B;
      margin: 40px;
      background-image: url("@/assets/images/documentation/phone-start-earn.png");
      background-repeat: no-repeat;
      background-position: 120% 20px;
      .last-text{
        font-size: clamp(12px, 1vw + 1vw, 24px);
        font-weight: 400;
      }

      @media (max-width: 1200px) {
        background-position: 90% 170%;
        margin: 20px;
      }

      @media (max-width: 500px) {
        background-position: 28% 170%;
      }
      &:hover{
        background-position: 125% 20px;

        @media (max-width: 1200px) {
          background-position: 90% 175%;
          margin: 20px;
        }

        @media (max-width: 500px) {
          background-position: 28% 175%;
        }
      }
    }
    &.card-purple{
      background: $purple;
      border-radius: 16px;
      margin: 40px;
      background-image: url("@/assets/images/documentation/dont-miss-opportunity.png");
      background-repeat: no-repeat;
      background-position: 120% 20px;
      transition: all .5s;
      .last-text{
        font-size: clamp(12px, 1vw + 1vw, 24px);
        font-weight: 400;
      }
      .btn-primary{
        background: $white !important;
        color: #2B2B2B !important;
      }

      @media (max-width: 1200px) {
        background-position: 90% 170%;
        margin: 20px;
      }

      @media (max-width: 500px) {
        background-position: 28% 170%;
      }

      &:hover{
        background-position: 125% 20px;

        @media (max-width: 1200px) {
          background-position: 90% 175%;
          margin: 20px;
        }

        @media (max-width: 500px) {
          background-position: 28% 175%;
        }
      }
    }
    .content-area{
      img.telephone{
        width: min( 100%, 665px );
      }
      .list-usefulness-bot{
        .icon{
          margin-right: 16px;
        }
        .list-usefulness-bot-text{
          .list-usefulness-bot-item-title{
            font-size: clamp(16px, 2vw + 1rem, 32px);
            font-weight: 500;
            color: #2F2F3B;
          }
          .list-usefulness-bot-item-subtitle{
            font-size: clamp(12px, 1vw + 1rem, 24px);
            font-weight: 400;
            color: #52526CBF;
          }
        }
      }
      .item-how-start{
        .item-how-start-title{
          .item-how-start-title-icon{
            height: 56px;
            width: 56px;
            background: $purple;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            font-size: clamp(16px, 2vw + 1rem, 32px);
            font-weight: 600;
            line-height: 41.6px;
            color: $white;

            @media (max-width: 720px) {
              height: 40px;
              width: 40px;
            }
          }
        }
      }
      .item-how-start-title-text{
        width: min(100%, 350px);
        padding: 0 0 0 20px;
        font-size: clamp(12px, 1vw + 1rem, 18px);
        font-weight: 600;
        line-height: 22px;
        text-transform: uppercase;
        color: $theme-body-font-color;
        @media (max-width: 1400px) {
          width: min(100%, 550px);
        }
      }
      .item-how-start-img {
        position: relative;
        .item-how-start-title-abs {
          position: absolute;
          z-index: 1;
          &.first{
            position: absolute;
            z-index: 1;
            top: 30%;
            left: -30%;
            transform: rotate(70deg);
          }
          &.second{
            position: absolute;
            z-index: 1;
            top: 30%;
            left: -30%;
            transform: rotate(165deg);
          }

          @media (max-width: 1400px) {
            display: none
          }
        }
        .item-how-start-title-abs-mobile {
          position: absolute;
          z-index: 1;
          width: 30vw;
          display: none;
          &.first{
            position: absolute;
            z-index: 1;
            width: 18vw;
            top: 75%;
            left: 39%;
            transform: rotate(30deg);
            @media (max-width: 550px) {
              width: 20vw;
              top: 70%;
            }
          }
          &.second{
            position: absolute;
            z-index: 1;
            top: 30%;
            left: 30%;
            transform: rotate(-40deg);
            @media (max-width: 550px) {
              width: 40vw;
            }

            @media (max-width: 400px) {
              top: 60%;
            }
          }

          @media (max-width: 1400px) {
            display: block;
          }
        }
        .item-how-start-title-img {
          position: relative;
          z-index: 2;
          
          @media (max-width: 1400px) {
            width: min( 100%, 253px );
          }

          @media (max-width: 800px) {
            width: min( 100%, 193px );
          }
        }
      }
    }
    .col-xl-7{

      .btn-primary{
        font-size: clamp(12px, 1vw + 1vw, 20px);
        font-weight: 400;
        padding: 16px 24px;

      }
      @media (max-width: 1200px) {
        padding-bottom: 420px;
      }
    }
  }


  /* global style inside page*/

  .list-usefulness-bot-item{
    margin: 20px 0
  }
  h1 {
    font-size: clamp(24px, 3vw + 1rem, 48px);
    font-weight: 600;
    color: #2B2B2B;

    &.app-title{
      font-size: clamp(24px, 3vw + 1rem, 64px);;
      font-weight: 600;
      line-height: 70.4px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

    }
  }

  h2{
    font-size: clamp(16px, 1vw + 1rem, 40px);
    font-weight: 600;
    line-height: 44px;

  }

}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}