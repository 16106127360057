.alone-page{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .wrapper-not-found{
    max-width: 900px;
    width: 100%;
    padding: 0 10px;
    .title-not-found{
      font-size: 35vw;
      font-weight: 600;
      line-height: 342px;
      letter-spacing: 0.699999988079071px;
      text-align: center;
      color: $danger;
      @media (min-width: 940px) {
        font-size: 285px;
      }
    }
    .btn-danger-custom {
      background-color: $danger;
      border-color: $danger;
      -webkit-tap-highlight-color: transparent;
      color: #fff;
      transform: translateZ(0);
    }
    .img-not-found{
      animation: error-smile 4s ease infinite;
    }
  }
}

.alone-page{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .wrapper-internal-server-error{
    max-width: 900px;
    width: 100%;
    padding: 0 10px;
    .title-internal-server-error{
      font-size: 35vw;
      font-weight: 600;
      line-height: 342px;
      letter-spacing: 0.699999988079071px;
      text-align: center;
      color: $purple;
      @media (min-width: 940px) {
        font-size: 285px;
      }
    }
    .btn-danger-custom {
      background-color: $purple;
      border-color: $purple;
      -webkit-tap-highlight-color: transparent;
      color: #fff;
      transform: translateZ(0);
    }
    .img-internal-server-error{
      animation: error-smile 4s ease infinite;
    }
  }
}

@keyframes error-smile {
  0% {
    transform: rotate(0deg)translateX(0px);
  }
  25% {
    transform: rotate(10deg)translateX(5px);
  }
  50% {
    transform: rotate(0deg)translateX(0px);
  }
  75% {
    transform: rotate(-10deg)translateX(-5px);
  }
  100% {
    transform: rotate(0deg)translateX(0px);
  }
}
