/**=====================
     22. User profile css start
==========================**/

//Edit profile //
.wrapper-form{
  .change-profile-wrapper{
    .card-body{
      .radio {
        margin: 0.5rem;
        color: $black-text;
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          + .radio-label {
            &:before {
              content: '';
              background: $color1;
              border-radius: 100%;
              border: 1px solid darken($color1, 25%);
              display: inline-block;
              width: 1.4em;
              height: 1.4em;
              position: relative;
              top: -0.2em;
              margin-right: 1em;
              vertical-align: top;
              cursor: pointer;
              text-align: center;
              transition: all 250ms ease;
            }
          }
          &:checked {
            + .radio-label {
              &:before {
                background-color: $color-main;
                box-shadow: inset 0 0 0 4px $color1;
              }
            }
          }
          &:focus {
            + .radio-label {
              &:before {
                outline: none;
                border-color: $color-main;
              }
            }
          }
          &:disabled {
            + .radio-label {
              &:before {
                box-shadow: inset 0 0 0 4px $color1;
                border-color: darken($color1, 25%);
                background: darken($color1, 25%);
              }
            }
          }
          + .radio-label {
            &:empty {
              &:before {
                margin-right: 0;
              }
            }
          }
          input[type="radio"]:checked + label::after {
            opacity: 0 !important;
          }
        }
      }
      .show-eyes{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: $purple;
        padding: 5px 10px;
        white-space: nowrap;
        border-radius: 5px;
        transition: all .3s;

        &:hover{
          background: #f5f5fd;
        }

        img{
          width: 25px;
          height: 25px;
        }
      }
      .hidden-block{
        visibility: hidden;
        opacity: 0;
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        background: #59667A1A;
        backdrop-filter: blur(5px);
        border-radius: 16px;
        z-index: 3;
        transition: all .3s;
        .show-description{
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #52526CBF;
        }
      }
      .hidden-block.active{
        visibility: visible;
        opacity: 1;
        transition: all .3s;
      }
      .container-passport{
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: all .3s;
        .cursor-pointer{
          white-space: nowrap;
          color: $purple
        }
        .checkbox label::before {
          background-color: $card-body-color;
          border: 1px solid #dfdfdf;
        }
        .checkbox input[type="checkbox"]{
          position: absolute;
        }
      }
      .active.container-passport{
        position: relative;
        visibility: visible;
        opacity: 1;
        transition: all .3s;
      }
    }
  }
  .modal-wrapper{
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: all .3s;
    .modal-content {
      background-color: white;
      max-width: 523px;
      width: 100%;
      border-radius: 15px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      z-index: 2;
      transform: translateY(-30px);
      transition: all .3s;
      .modal-header{
        padding: 10px 15px;
        border-bottom: 1px solid $gray-for-border;
      }
    }
    .modal-footer{
      padding: 10px 15px;
      border-top: 1px solid $gray-for-border;
    }
  }
  .modal-wrapper.active{
    visibility: visible;
    opacity: 1;
    transition: all .3s;
    .modal-content {
      transform: translateY(0px);
      transition: all .3s;
    }
  }
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}


.wrapper-verification{
  .title-verification{
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    letter-spacing: 0.5px;
    color: $purple;
  }
  .description-status{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.699999988079071px;
    text-align: left;
    color: #212529BF;

  }
}


/**=====================
     22. User profile css Ends
==========================**/




