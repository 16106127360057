
.no-image {
  background-size: 50px 50px; /* Размер иконки */
  background-repeat: no-repeat;
  background-position: center center; /* Выравнивание иконки по верхнему краю */
}

.ql-editor.ql-blank {
  opacity: 0 !important;
  color: gray; /* Цвет текста placeholder */
}
.btn.btn-outline-primary:hover{
  background: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}
