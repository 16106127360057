.wrapper-become{
 .card{
   width: min(100%, 1200px);
   margin: 0 auto;
   textarea.form-control{
     resize: none;
     height: 70px;
     &::placeholder{
       color: $second-text;
     }
   }
   input[type='file']{
     display: none;
   }
   input{
     &::placeholder{
       color: $second-text;
     }
   }
   .btn.btn-outline-primary{
     &:hover {
       background: var(--theme-deafult) !important;
       border-color: var(--theme-deafult) !important;
     }
   }
   label.url-site-label{
     position: relative;
     &:hover, &:active {
       background-color: var(--bs-body-bg);
       border-color: #86b7fe;
       outline: 0;
       box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
       &:after {
         background-color: #77c639;
       }
     }
     p{
       color: $second-text;
     }
     img{
       position: absolute;
       right: 0;
       top: calc(50%);
       transform: translate(-50%, -50%);
     }
   }
 }
  .modal-wrapper {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: all .3s;
    .modal-content {
      background-color: white;
      max-width: 523px;
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      z-index: 2;
      transform: translateY(-30%);
      transition: all .3s;
    }
  }
  .modal-wrapper.active{
    visibility: visible;
    opacity: 1;
    transition: all .3s;
    .modal-content {
      background-color: white;
      max-width: 523px;
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      z-index: 2;
      transform: translateY(0%);
      transition: all .3s;
    }
  }
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}