.drop-down-btn {
  color: #7366FF;
  padding: 6px 18px;
  font-size: 16px;
  border: 1px solid #7366FF;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
}

.wrapper-founders{
  .card-header{
    ul.balance-data{
      li{
        white-space: nowrap;
      }
    }
  }
  .dropdown {
    cursor: pointer;
    position: relative;
    .show-div {
      box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
      transform: translateY(30px);
      opacity: 0;
      visibility: hidden;
      left: 0;
      top: 50px;
      position: absolute;
      z-index: 999;
      background-color: #fff;
      transition: all linear 0.3s;
    }
  }
  .dropdown:hover .show-div, input:focus{
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
    border-radius: 5px;
    overflow: hidden;
  }
  .table-wrapper{
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #ccc #f1f1f1;
    .light-container{
      white-space: nowrap;
    }
    th{
      white-space: nowrap;
    }
  }
  .table-wrapper::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .table-wrapper::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  .table-wrapper::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 6px;
  }

  .table-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }
  @media (max-width: 560px) {
    .items-payment{
      padding: 0;
    }
  }
}

.card-user-statistics{
    .card-header{
      .wrapper-switch{
        background: #F5F6F9;
        border-radius: 2px;
        padding: 4px;
        overflow: hidden;
        .btn-switch{
          transition: all .3s;
        }
        .btn-check:checked + .btn-switch {
          border-radius: 2px;
          background-color: $white;
          color: $purple;
          transition: all .3s;
        }
      }
    }
    .card-body{

      .item-slide {
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      .item-slide[v-show] {
        opacity: 1;
      }

      .item-slide {
        opacity: 0;
        transform: translateX(50%);
        transition: all 0.3s;
      }

      .item-slide.active-slide {
        opacity: 1;
        transform: translateX(0);
      }

      .item-slide.disable-slide {
        opacity: 0;
        transform: translateX(50%);
      }

      .amount{
        font-size: 24px;
        font-weight: 500;
        line-height: 28.8px;
        .text-second{
          font-size: 24px;
          font-weight: 500;
          line-height: 28.8px;
          color: #52526CBF;
        }
      }
    }
}

/* ==========
 founders-users-update
 ========== */

.wrapper-founders-users{
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  .controller{
    position: relative;
    transition: all .3s;
  }
  .controller.active-status-first::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
    height: 2px;
    background: $purple;
    transition: all .3s;
  }
  .controller.active-status-second::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 40%;
    width: 60%;
    height: 2px;
    background: $purple;
    transition: all .3s;
  }
  .left-card-menu{
    .left-card-menu-filters{
      .top-filters{
        .wrapper-switch{
          background: $purple;
          border-radius: 5px;
          color: $white;
          .switch-input-single{
            position: relative;
            .btn-check{
              position: absolute;
              z-index: -1;
              opacity: 0;
            }
            .btn-switch{
              &:before,&:after{
                content: '';
                position: absolute;
                top: 50%;
                border-radius: 50%;
              }
            }
            .btn-switch::before{
              left: 0;
              width: 20px;
              height: 20px;
              background: $white;
              box-shadow: 0 0 1px grey;
              transform: translateY(-50%);
            }
            .btn-switch::after{
              left: 5px;
              width: 10px;
              height: 10px;
              transform: translateY(-50%);
              background: $purple;
              box-shadow: 0 0 1px grey;
              opacity: 0;
              transition: all .3s;
            }
            .btn-check:checked + .btn-switch::after{
              opacity: 1;
              transition: all .3s;
            }
          }
        }
        .dropdown {
          cursor: pointer;
          position: relative;
          .drop-down-btn{
            background: $purple;
            color: $white;
          }
          .show-div {
            box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
            transform: translateY(30px);
            opacity: 0;
            visibility: hidden;
            left: 0;
            top: 50px;
            position: absolute;
            z-index: 999;
            min-width: 200px;
            background-color: #fff;
            transition: all linear 0.3s;
          }
        }
        .dropdown:hover .show-div, #datetime:focus, #datetime-end:focus, .show-div:hover{
          opacity: 1;
          transform: translateY(0px);
          visibility: visible;
          border-radius: 5px;
          background: $white;
          overflow: hidden;
        }
      }
    }
    .left-card-menu-searh{
      position: relative;
      .form-control {
        padding-left: 30px;
      }
      .form-control::placeholder{
        font-size: 14px;
        font-weight: 400;
        line-height: 23.8px;
        letter-spacing: 0.699999988079071px;
        color: #52526CBF;
      ;
      }
      .fa-search {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #999;
      }
    }
    .search-spisok{
      .spisok-sortirovki{
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #52526CBF;
      }
      .wrapper-user{
        overflow: hidden;
        &:hover{
          background: #F8F7FF;
          border-radius: 5px;
          transition: all .3s;
        }
        .avatar-user{
          .avatar-circle{
            position: relative;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            color: $white;
            font-size: 20px;
            .avatar-text{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 24px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
        .brief-description{
          .full-name{
            font-size: 14px;
            font-weight: 400;
            line-height: 23.8px;
            letter-spacing: 0.699999988079071px;
            color: #2F2F3B;
          }
          .email{
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #52526CBF;
          }
        }
      }
    }
  }
  .right-card-menu{
    overflow: hidden;
    .wrapper-user{
      .card-title{
        border-bottom: 1px solid #ECF3FA;
      }
      .card-wrapper{
        .card-profile{
          .profile-avatar{
            .profile-avatar-item{
              width: 100px;
              height: 100px;
              border-radius: 50%;
              font-size: 48px;
              font-weight: 400;
              line-height: 24px;
              color: $white;
            }
          }
        }
      }
      .wrapper-network{
        position: relative;
        width: 0%;
        transition: all .3s;
        overflow: hidden;
        .wrapper-network-list{
          position: absolute;
          top: 0;
          left: 0;
          border-right: 1px solid #ECF3FA;
          transition: all .3s;

          .network-scroll{
            overflow: auto;
          }
          .network-scroll::-webkit-scrollbar {
            width: 12px;
            height: 12px;
          }

          .network-scroll::-webkit-scrollbar-track {
            background-color: #f1f1f1;
          }

          .network-scroll::-webkit-scrollbar-thumb {
            background-color: #ccc;
            border-radius: 6px;
          }

          .network-scroll::-webkit-scrollbar-thumb:hover {
            background-color: #999;
          }
          .network-item{
            border-bottom: 1px solid #ECF3FA;
            &:hover{
              background: #F8F7FF;
              border-radius: 5px;
              transition: all 0.3s;
            }
            .avatar-circle{
              position: relative;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              color: $white;
              font-size: 20px;
              .avatar-text{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 24px;
                font-weight: 400;
                line-height: 24px;
              }
            }
          }
        }
      }

      .wrapper-profile{
        width: 100%;
        transition: all .3s;
        .wrapper-close-btn{
          position: absolute;
          top: -10px;
          left: 270px;
          opacity: 0;
          transition: all .3s;
          &:hover{
            + .wrapper-network {
              left: -30px;
              .wrapper-close-btn{
                position: absolute;
                top: 0;
                left: 300px;
                transition: all .3s;
                opacity: 1;

                .close-btn {
                  position: relative;
                  cursor: pointer;
                  background: $white;
                  width: 50px;
                  height: 50px;
                  &::before,
                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 30px;
                    height: 3px;
                    background-color: #52526CBF;
                    transform: translate(-50%, -50%) rotate(45deg);
                  }
                  &::after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                  }
                }
              }
            }
          }
          .close-btn {
            position: relative;
            cursor: pointer;
            background: $white;
            width: 50px;
            height: 50px;
            &::before,
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              width: 30px;
              height: 3px;
              background-color: #52526CBF;
              transform: translate(-50%, -50%) rotate(45deg);
            }
            &::after {
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
        }
      }
      .wrapper-network{
        position: absolute;
        left: 0;
        top: 0;
        transition: all .3s;
      }
      .wrapper-network, .wrapper-profile{
        transition: all .3s;
      }
    }
    .wrapper-user {
      .active {
        overflow: hidden;
        .wrapper-network {
          width: 300px;
          height: 100%;
          background: $white;
          transition: all .3s;
        }

        .wrapper-profile {
          cursor: pointer;

          &:hover{
            + .wrapper-network {
              left: -30px;
              transition: all .3s;
              .wrapper-close-btn{
                position: absolute;
                top: 0;
                left: 270px;
                transition: all .3s;
                opacity: 1;
                transition: all .3s;

                .close-btn {
                  position: relative;
                  cursor: pointer;
                  background: $white;
                  width: 50px;
                  height: 50px;
                  &::before,
                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 30px;
                    height: 3px;
                    background-color: #52526CBF;
                    transform: translate(-50%, -50%) rotate(45deg);
                  }
                  &::after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                  }
                }
              }
            }
          }
        }

        .wrapper-profile:hover{
          .wrapper-close-btn{
            position: absolute;
            top: 0;
            left: 270px;
            opacity: 1;
            transition: all .3s;
            &:hover{
              + .wrapper-network {
                left: -30px;
                .wrapper-close-btn{
                  position: absolute;
                  top: 0;
                  left: 300px;
                  transition: all .3s;
                  opacity: 1;

                  .close-btn {
                    position: relative;
                    cursor: pointer;
                    background: $white;
                    width: 50px;
                    height: 50px;
                    &::before,
                    &::after {
                      content: "";
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      width: 30px;
                      height: 3px;
                      background-color: #52526CBF;
                      transform: translate(-50%, -50%) rotate(45deg);
                    }
                    &::after {
                      transform: translate(-50%, -50%) rotate(-45deg);
                    }
                  }
                }
              }
            }
            .close-btn {
              position: relative;
              cursor: pointer;
              background: $white;
              width: 50px;
              height: 50px;
              &::before,
              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 30px;
                height: 3px;
                background-color: #52526CBF;
                transform: translate(-50%, -50%) rotate(45deg);
              }
              &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
              }
            }
          }
        }
        .wrapper-network {
          transition: all .3s;
        }
      }
    }
    .wrapper-no-selected{
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #52526CBF;
    }
  }
}

/* ==========
 animation
 ========== */

@keyframes underline-animation-left {
  0% {
    width: 0;
  }
  50%{
    width: 100%;
  }
  100% {
    width: 40%;
  }
}

@keyframes underline-animation-right {
  0% {
    width: 0;
  }
  50%{
    width: 100%;
  }
  100% {
    width: 60%;
  }
}

/* ==========
 scroll css
 ========== */

.card-scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.card-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}

.card-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

/* ==========
 founders-users
 ========== */

.wrapper-founders-users{

  .card-scroll{
    max-height: 415px;
    overflow-y: auto;
  }
  .modal-send-message{
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .modal-profile-avatar-item{
    margin-bottom: 16px;
  }
  .modal-profile-wrapper{
    padding-top: 18px;
  }
  .modal-profile-des-name{
    font-weight: 500;
    font-size: 20px;
  }
  .modal-title-text{
    font-weight: 500;
    font-size: 20px;
  }
  .modal-wrapper {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: all .3s;
    .modal-content {
      background-color: white;
      max-width: 523px;
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      z-index: 2;
      transform: translateY(-30%);
      transition: all .3s;
    }
  }
  .modal-wrapper.active{
    visibility: visible;
    opacity: 1;
    transition: all .3s;
    .modal-content {
      background-color: white;
      max-width: 523px;
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      z-index: 2;
      transform: translateY(0%);
      transition: all .3s;
    }
  }

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }


  .modal-message {
    font-size: 24px;
    font-weight: bold;
    border-bottom: 1px solid #DEE2E6;
    padding-bottom: 18px;
  }
  .leftmenu-item{
    flex-direction: column;
  }
  .profile-des{
    padding-left: 20px;
  }
  .card-wrapper{
    padding: 15px;
  }
  .gray{
    color: rgba(137, 137, 137, 1);
  }
  .purple{
    color: var(--theme-deafult) !important;
  }
  .top-left-card-menu{
    position: relative;
    gap: 10px;
  }
  .top-left-card-menu-item{
    color: #52526CBF;
    cursor: pointer;
    height: 100%;
  }
  .height-bottom{
    border-bottom: 1px solid #CBCBD3;
  }
  .purple-height::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
    height: 1px;
    background-color: var(--theme-deafult);
    animation: underline-animation 0.5s ease-in-out;
  }

  .card-item-left{
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
  }
  .btn-border-right{
    border-right: 2px solid #ECF3FA;
  }
  .profile-avatar-item{
    border-radius: 360px;
  }
  .drop-down-btn{
    transition: all .3s;
  }
  .drop-down-btn:hover{
    background: $purple;
    color: $white;
    transition: all .3s;
  }
}

