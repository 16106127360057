.wrapper-modalPayment{
  .card-left-bottom{
    padding: 32px;
    max-width: 650px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    .card-left-title{
      margin-bottom: 10px;
      font-size: 28px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #212529;
    }
    .card-left-title-bottom{
      color: #52526CBF;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    .payment-wrapper{
      @media screen and (max-width: 1476px){
        flex-direction: column;
      }
      .payment{
        width: 49%;
        @media screen and (max-width: 1476px){
          width: 100%;
        }
      }
    }
    .payment-apply{
      @media screen and (max-width: 1780px){
        flex-direction: column;
      }
      .checkbox{
        .text-muted{
          color: #212529;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.5px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

        }
      }
    }
  }
}