.wrapper-projects-top{
  .wrapper-projects-top-title{

  }
  .wrapper-projects-top-items{
    .img-wrapper{
      position: relative;
      max-height: 110px;
      height: 110px;
      overflow: hidden;
      border-radius: 6px;
      .projects-top-item-img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .wrapper-subtitle{
      .wrapper-subtitle-title{
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        color: #212529;
      }
      .wrapper-subtitle-author{
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
        color: $second-text;
        p{
          font-size: 12px;
          font-weight: 400;
          line-height: 26px;
          color: #7366FF;
        }
      }
      .more-about{
        color: $second-text;
        p{
          font-size: 14px;
          font-weight: 500;
          line-height: 26px;
          white-space: nowrap;   /* Не переносить текст на новую строку */
          overflow: hidden;      /* Скрыть переполняющий текст */
          text-overflow: ellipsis;
        }
        .title{
          font-size: 12px;
          font-weight: 400;
          line-height: 26px;
        }
      }
      .btn-more{
        background: #F1EFFF;
        border-radius: 6px;
        padding: 10px 0;
      }
    }
  }
}