.wrapper-detail-project{
  margin-bottom: 60px;
  .container-fluid{
    .row{
      .preview{
        padding: 32px;
        overflow: hidden;
        border-radius: 8px;
        margin: 0 auto;
        img{
          max-width: 877px;
          max-height: 694px;
          border-radius: 16px;
          width: 100%;
          height: auto;
          display: block;
        }
      }
      .slider-container {
        position: relative;
        overflow: hidden;
        .slider {
          display: flex;
          align-items: center;
          .arrow {
            background-color: transparent;
            border: none;
            cursor: pointer;
          }
          .arrow.left, .arrow.right {
            font-size: 24px;
          }
          .slides {
            display: flex;
            transition: transform 0.5s ease;
            gap: 15px;
            .slide {
              min-width: 10%;
              width: 426px;
              height: 266px;
              img{
                width: 426px;
                height: 266px;
              }
            }
          }

        }
      }
      .left-menu{
        .card-left{
          padding: 35px 46px;
          .ShareProject{
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #7366FF;
          }
          .author-city-category{
            border-top: 1px solid #DEE2E6;
            border-bottom: 1px solid #DEE2E6;
            margin-top: 32px;
            padding-top: 34px;
            margin-bottom: 32px;
            padding-bottom: 32px;
            .author{
              width: 295px;
              .author-title{
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #212529;
              }
              .author-name{
                display: flex;
                justify-content: left;
                width: 120px;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #212529;
              }
            }
          }
          .conditions{
            padding-bottom: 32px;
            margin-bottom: 32px;
            border-bottom: 1px solid #DEE2E6;
            .conditions-title{
              font-size: 16px;
              font-weight: 500;
              line-height: 18px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #212529;
            }
            .conditions-item-wrapper{
              .conditions-top{
                @media screen and (max-width: 1532px){
                  flex-direction: column;
                  width: 100%
                }
                .conditions-item{
                  .conditions-item-inner{
                    background: #F6F7F9;
                    border-radius: 6px !important;
                    padding: 12px 16px;
                    max-width: 196px;
                    width: 100%;
                    @media screen and (max-width: 1532px){
                      flex-direction: column;
                    }
                    .conditions-item-title{
                      color: #52526CBF;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 18px;
                      text-align: left;
                      text-underline-position: from-font;
                      text-decoration-skip-ink: none;
                    }
                    .conditions-item-name-green{
                      color: #54BA4A;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 24px;
                      text-align: left;
                      text-underline-position: from-font;
                      text-decoration-skip-ink: none;
                    }
                    .conditions-item-name{
                      color: #7366FF;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 24px;
                      text-align: left;
                      text-underline-position: from-font;
                      text-decoration-skip-ink: none;
                    }
                  }
                }
              }
              .conditions-item{
                .conditions-item-inner{
                  background: #F6F7F9;
                  border-radius: 6px !important;
                  padding: 12px 16px;
                  max-width: 196px;
                  width: 100%;
                  .conditions-item-title{
                    color: #52526CBF;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                  }
                  .conditions-item-name{
                    color: #7366FF;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                  }
                }
            }
            }

          }
          .title{
            margin-bottom: 10px;
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #212529;
          }
          .progressBar{
            position: relative;
            background-color: #e0e0e0;
            border-radius: 8px;
            height: 8px;
            margin-top: 10px;
            .progress {
              background-color: #7366FF;
              height: 100%;
              border-radius: inherit;
              transition: width 0.5s ease;
            }
            .progressDot {
              position: absolute;
              bottom: -2px;
              width: 12px;
              height: 12px;
              background-color: #7366FF;
              border-radius: 50%;
              transform: translateX(-50%);
            }
              .progressText{
                position: absolute;
                left: -30px;
                bottom: 15px;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                color: #7366FF;
                transform: translateX(-40%);
            }

          }
          .progressInfo {
            margin-top: 8px;
            .progressInfo-title{
              color: #212529;
              font-size: 16px;
              font-weight: 400;
              line-height: 18px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
            .progressInfo-number{
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #7366FF;

            }
          }
        }
        .card-left-bottom{
          padding: 32px;
          .card-left-title{
            margin-bottom: 10px;
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #212529;
          }
          .card-left-title-bottom{
            color: #52526CBF;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

          }
          .payment-wrapper{
            @media screen and (max-width: 1476px){
              flex-direction: column;
            }
            .payment{
              width: 49%;
              @media screen and (max-width: 1476px){
                width: 100%;
              }
            }
          }
          .payment-apply{
            @media screen and (max-width: 1780px){
              flex-direction: column;
            }
            .checkbox{
              .text-muted{
                color: #212529;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0.5px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;

              }
            }
          }
        }
      }
      .card-desc{
        padding: 20px;
      }
    }
  }
}