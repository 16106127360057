.userService_wrapper{
  .card{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    .text-content {
      overflow: hidden;
      transition: max-height 0.3s ease;
    }

    .text-content.collapsed {
      max-height: 200px; /* Ограничиваем высоту */
    }

    .text-content:not(.collapsed) {
      max-height: none;
    }
    .description{
      @media (max-width: 768px) {
        overflow: hidden;
        max-height: 200px;
        transition: all .5s;
      }
    }
    .description.full{
      max-height: 100vh;
      transition: all .5s;
    }
    .btn-primary{

      @media (max-width: 768px) {
        display: block;
      }
    }
    .description{
      p{
        font-family: Rubik, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2 !important;
        color: $black-text;
      }
    }
    h5{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: $black-text;
    }
    .wrapper-switch {
      color: $purple;

      .btn-check:checked + .btn-switch {
        border-radius: 2px;
        transition: all 0.3s;

        &::after { /* Используем ::after для линии */
          content: '';
          position: absolute;
          bottom: -5px; /* Расположение линии под элементом */
          left: 0;
          right: 0; /* Занимает всю ширину родителя */
          height: 2px; /* Толщина линии */
          background: $purple; /* Цвет линии */
          transition: all 0.3s; /* Плавный переход */
        }
      }
      .btn-switch {
        position: relative; /* Убедитесь, что элемент имеет позиционирование */

        &::after {
          content: '';
          position: absolute;
          bottom: -5px; /* Расположение линии под элементом */
          left: 0;
          right: 0; /* Занимает всю ширину родителя */
          height: 2px; /* Толщина линии */
          background: transparent; /* Начальный цвет линии (прозрачный) */
          transition: all 0.3s; /* Плавный переход */
        }
      }
    }
  }
  .wrapper-table{
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
  }
}
