$color1: #D9D9D9;
$color-main:  #7366FF;

.wrapper-settings{
  .wrapper-items-settings{
    .wrapper-auto{
      .switcher {
        color: #FFFFFFAA;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        gap: 10px;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
      }
      label {
        position: relative;
        display: inline-flex;
        width: 40px;
        height: 20px;
        padding: 3px;
      }
      label input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      label b {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #00000055;
        -webkit-transition: .5s;
        transition: .5s;
        border-radius: 50px;
      }
      label b:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        top: 0;
        left: 0;
        margin: 1px;
        background-color: #FFF;
        -webkit-transition: .5s;
        transition: .5s;
        border-radius: 50%;
        box-shadow: 0 2px 5px 0 #00000055;
      }
      label input:checked + b {
        background-color: $color-main;
      }
      label input:checked + b:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
    }
    .item-settings{
      max-width: 800px;
      .item-settings-secondary{
        .item-settings-choice{
          position: relative;
          .row{
            position: absolute;
            visibility: hidden;
            opacity: 0;
            height: 0;
            transition: all 0s;
            .btn{
              transition: all .3s;
              &.success-send-message{
                background: rgba(84, 186, 74, 0.8) !important;
                border-color: rgba(84, 186, 74, 0.8) !important;
                transition: all .3s;
              }
              &.error-send-message{
                background: $danger !important;
                border-color: $danger !important;
                transition: all .3s;
              }
            }
          }
          &.active{
            .row{
              position: relative;
              visibility: visible;
              opacity: 1;
              height: auto;
              transition: all .5s;
            }
          }
        }
      }
      .default-according{
        max-height: 400px;
        overflow: auto;
      }
      .card-header-upper {
        text-decoration: uppercase;
      }

      .center {
        display: flex;
        align-items: center;
        justify-content: right;
      }

      .card-header-upper {
        text-decoration: uppercase;
      }

      .wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
      }

      .tables {
        display: none;
      }

      .default-according {
        display: block;
      }

      .copied {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .copied p {
        background-color: rgba(84, 186, 74, 0.8);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        font-size: 14px;
        border-radius: 5px;
      }

      .btn-outline-primary {
        color: var(--theme-deafult);
        border-color: var(--theme-deafult) !important;
      }
      .btn-outline-primary:hover {
        color: #fff;
        background-color: var(--theme-deafult) !important;
        border-color: var(--theme-deafult) !important;
      }
      .item-settings-title{
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
      }
      .checkbox-green{

      }
      .radio {
        margin: 0.5rem;
        color: $color-main;
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          + .radio-label {
            &:before {
              content: '';
              background: $color1;
              border-radius: 100%;
              border: 1px solid darken($color1, 25%);
              display: inline-block;
              width: 1.4em;
              height: 1.4em;
              position: relative;
              top: -0.2em;
              margin-right: 1em;
              vertical-align: top;
              cursor: pointer;
              text-align: center;
              transition: all 250ms ease;
            }
          }
          &:checked {
            + .radio-label {
              &:before {
                background-color: $color-main;
                box-shadow: inset 0 0 0 4px $color1;
              }
            }
          }
          &:focus {
            + .radio-label {
              &:before {
                outline: none;
                border-color: $color-main;
              }
            }
          }
          &:disabled {
            + .radio-label {
              &:before {
                box-shadow: inset 0 0 0 4px $color1;
                border-color: darken($color1, 25%);
                background: darken($color1, 25%);
              }
            }
          }
          + .radio-label {
            &:empty {
              &:before {
                margin-right: 0;
              }
            }
          }
          input[type="radio"]:checked + label::after {
            opacity: 0 !important;
          }
        }
      }
    }
    .alert-wrapper{
      position: fixed;
      right: 0;
      top: 150px;
      z-index: 999;
      translate: all .3;
    }

    .fade-enter-active, .fade-leave-active {
      opacity: 1;
      transition: all .3s;
    }
    .fade-enter, .fade-leave-to {
      opacity: 0;
      transition: all .3s;
    }
  }
}