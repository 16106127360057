@import 'vendors/font-awesome.scss';
@import 'vendors/icofont.scss';
@import "vendors/themify/_themify-icons.scss";
@import "vendors/flag-icon.scss";
@import "vendors/feather-icon/feather-icon";
@import "vendors/whether-icon.scss";
@import 'vendors/scrollbar.scss';
@import 'vendors/animate.scss';
@import 'vendors/prism.scss';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import "style";
@import "responsive";
@import "color-1.scss";
@import "vendors/dropzone.scss";
@import "./vendors/image-cropper.scss";
@import "./components/user";
@import "./components/carousel";
// @import "vendors/owlcarousel.scss";
// @import "vendors/icofont.scss";
@import "vendors/chartist.scss";
// PAGES
@import "pages/become";
@import "pages/partners";
@import "pages/settings";
@import "pages/capital";
@import "pages/dashboard-user";
@import "pages/error-page";
@import "pages/founders";
@import "pages/user-edit";
@import "pages/service-feedback";
@import "pages/courses";
@import "pages/documentaion";

/* ---------------------
	*** list ***
-----------------------*/

li{
  list-style-type: none;
}

hr{
  background: $gray-for-border !important;
}

.text-purple{
  color: $purple
}

/* ---------------------
	*** position ***
-----------------------*/

.position-initial{
  position: initial !important;
}

/* ---------------------
	*** custom class for bootstrap ( *row ( >=1600px )) ***
-----------------------*/

@media (min-width: 1600px) {
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.3333333%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
}
