<template>
  <div>
    <Breadcrumbs main="МОЙ КАПИТАЛ" title="МОЙ КАПИТАЛ"/>
    <div class="col-xxl-10 col-md-12 box-col-8 grid-ed-12">
      <div class=" container-fluid">
        <div class="row">
          <p class="alert alert-dark outline" v-if="credit">
            Компанией направлено требование в банк о возврате Вам денежных средств. В течении 3 дней с даты
            возврата, ожидаем от Вас повторного проведения неуспешного платежа. <br/>
            Повторить платеж:
            <ol>
              <li v-if="credit.creditSubscribe>0">
                <a href="https://c.cloudpayments.ru/payments/a96565378eb544efa1f955f77df38d5e">Абонентская плата - 1500
                  рублей.</a></li>
              <li v-if="credit.creditCourse>=5000">
                <a href="https://c.cloudpayments.ru/payments/d01dc624a6d6498d85eb608a9aa03672">Оплата курса - 5 000
                  рублей.</a></li>
              <li v-if="credit.creditCourse>=25000"><a
                  href="https://c.cloudpayments.ru/payments/f0ecd07a0c8640c8bf4d1ebb4ba1d76a">Оплата курса - 25 000
                рублей.</a></li>
            </ol>

            <p v-if="this.credit.creditCertificate>0">
              Вам необходимо повторить платеж на: {{ this.credit.creditCertificate }} рублей, по оплате сертификатов.
              <ol>
                <li v-if="credit.creditCertificate>=1000"><a
                    href="https://c.cloudpayments.ru/payments/9998951a18c6466b9eca64a46d30805a">
                  Оплата сертификатов - 1 000 рублей.</a></li>
                <li v-if="credit.creditCertificate>=5000"><a
                    href="https://c.cloudpayments.ru/payments/0fa9374053f648d58baab34576684f84">
                  Оплата сертификатов - 5 000 рублей.</a></li>
                <li v-if="credit.creditCertificate>=10000"><a
                    href="https://c.cloudpayments.ru/payments/59ff72d0cb3041478c4eeb94eeb0f2ac">
                  Оплата сертификатов - 10 000 рублей.</a></li>
                <li v-if="credit.creditCertificate>=50000"><a
                    href="https://c.cloudpayments.ru/payments/143e3b049a6d43ad830fd6d0f265812c">
                  Оплата сертификатов - 50 000 рублей.</a></li>
              </ol>
            </p>

            По любым вопросам обращайтесь в тех. поддержку.
          </p>
          <alert-info v-if="!isSubscription"
                      text="Чтобы начать использование сервиса, Вам нужно оплатить подписку. <a href='/my/users/activate'> Оплатить подписку можно здесь. </a>"/>

          <alert-info v-if="accountActivityEndDate"
                      :text="'Ваша личная активность зафиксирована до '+ accountActivityEndDate+'. ' +
                   '<b>Для дальнейшего продолжения начислений бонусов,</b> пожалуйста, заблаговременно подтверждайте личную активность:\n'+
                    '<b>совершайте покупку сертификатов от 1 000 ₽ ежемесячно!</b>\n'"/>

          <alert-info v-if="!accountActivityEndDate"
                      text="Для начисления бонусов необходимо приобрести сертификаты на сумму от 1 000 ₽."/>

          <div class="col-lg-3 col-sm-6">
            <div class="card small-widget">
              <div class="card-body primary"><span class="f-light">ЛИЧНЫЕ ПАРТНЁРЫ</span>
                <div class="d-flex align-items-end gap-1">
                  <h4>{{ active6 ? '****' : format(data[0]) }}</h4>
                  <span class="font-primary f-12 f-w-500">
                    <i class="icon-arrow-up" v-if="!active6"></i>
                    <span v-if="!active6">+{{ data[1] }}</span>
                  </span>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use xlink:href="/img/icon-sprite.cec624bc.svg#new-order"></use>
                  </svg>
                  <div class="show-hide" @click.prevent="show('active6')">
                    <img v-if="active6" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                    <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                  </div>
                </div>
                <div>
                  <vue-feather
                      @mouseover="showPopover1=true" @mouseleave="showPopover1=false"
                      class="status_toggle middle sidebar-toggle"
                      type="help-circle"
                  ></vue-feather>

                  <div v-show="showPopover1" class="popover">
                    Количество партнеров на первом уровне и рост за месяц.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="card small-widget">
              <div class="card-body warning"><span class="f-light">МОЯ СЕТЬ</span>
                <div class="d-flex align-items-end gap-1"><h4>{{ active7 ? '****' : format(data[2]) }}</h4><span
                    class="font-warning f-12 f-w-500"><i
                    class="icon-arrow-up" v-if="!active7"></i><span v-if="!active7">+{{ data[3] }}</span></span></div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use xlink:href="/img/icon-sprite.cec624bc.svg#customers"></use>
                  </svg>
                  <div class="show-hide" @click.prevent="show('active7')">
                    <img v-if="active7" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                    <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                  </div>
                </div>
                <div>
                  <vue-feather
                      @mouseover="showPopover2=true" @mouseleave="showPopover2=false"
                      class="status_toggle middle sidebar-toggle"
                      type="help-circle"
                  ></vue-feather>

                  <div v-show="showPopover2" class="popover">
                    Количество партнеров на всех уровнях и рост за месяц.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="card small-widget">
              <div class="card-body secondary"><span class="f-light">БАЛАНС</span>
                <div class="d-flex align-items-end gap-1"><h4><span v-if="active4">****</span><span v-else> {{ format(data[5]) }} <span style="font-size: 16px; font-weight: 300;">&#8381;</span></span></h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use xlink:href="/img/icon-sprite.cec624bc.svg#sale"></use>
                  </svg>
                  <div class="show-hide" @click.prevent="show('active4')">
                    <img v-if="active4" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                    <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                  </div>
                </div>
                <div>
                  <vue-feather
                      @mouseover="showPopover3=true" @mouseleave="showPopover3=false"
                      class="status_toggle middle sidebar-toggle"
                      type="help-circle"
                  ></vue-feather>

                  <div v-show="showPopover3" class="popover">
                    Сумма средств на всех Ваших счетах.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="card small-widget">
              <div class="card-body success"><span class="f-light">МОИ АКТИВЫ</span>
                <div class="d-flex align-items-end gap-1"><h4>
                  {{ active3 ? '****' : format(totalValue) + "&#8381;" }}</h4><span
                    class="font-primary f-12 f-w-500"><i
                    class="icon-arrow-up" v-if="!active3"></i><span v-if="!active3">+{{
                    percentageChange
                  }}%</span></span></div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use xlink:href="/img/icon-sprite.cec624bc.svg#profit"></use>
                  </svg>
                  <div class="show-hide" @click.prevent="show('active3')">
                    <img v-if="active3" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                    <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                  </div>
                </div>
                <div>
                  <vue-feather
                      @mouseover="showPopover4=true" @mouseleave="showPopover4=false"
                      class="status_toggle middle sidebar-toggle"
                      type="help-circle"
                  ></vue-feather>

                  <div v-show="showPopover4" class="popover">
                    Сумма активов, и изменение от стоимости покупки.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="card small-widget">
              <div class="card-body secondary"><span class="f-light">ДОХОД ОБЩИЙ</span>
                <div class="d-flex align-items-end gap-1">
                  <h4>{{ active2 ? '****' : format(profit.profitGeneral) + "&#8381;" }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use xlink:href="/img/icon-sprite.cec624bc.svg#sale"></use>
                  </svg>
                  <div class="show-hide" @click.prevent="show('active2')">
                    <img v-if="active2" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                    <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                  </div>
                </div>
                <div>
                  <vue-feather
                      @mouseover="showPopover5=true" @mouseleave="showPopover5=false"
                      class="status_toggle middle sidebar-toggle"
                      type="help-circle"
                  ></vue-feather>

                  <div v-show="showPopover5" class="popover">
                    Ваш доход за все время.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="card small-widget">
              <div class="card-body secondary"><span class="f-light">ДОХОД ЗА МЕСЯЦ</span>

                <div class="d-flex align-items-end gap-1">
                  <h4>{{ active1 ? '****' : format(profit.profitInMonth) + "&#8381;" }}</h4>
                </div>
                <div class="col-lg-3 col-sm-6">
                </div>

                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use xlink:href="/img/icon-sprite.cec624bc.svg#sale"></use>
                  </svg>
                  <div class="show-hide" @click.prevent="show('active1')">
                    <img v-if="active1" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                    <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                  </div>
                </div>
                <div>
                  <vue-feather
                      @mouseover="showPopover6=true" @mouseleave="showPopover6=false"
                      class="status_toggle middle sidebar-toggle"
                      type="help-circle"
                  ></vue-feather>

                  <div v-show="showPopover6" class="popover">
                    Ваш доход за текущий месяц.
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="card small-widget">
              <div class="card-body secondary"><span class="f-light">ДОСТУПНО</span>

                <div class="d-flex align-items-end gap-1">
                  <h4>{{ active5 ? '****' : format(AvailableWithdrawal.points) + "&#8381;" }}</h4>
                </div>
                <div class="col-lg-3 col-sm-6">
                </div>

                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use xlink:href="/img/icon-sprite.cec624bc.svg#sale"></use>
                  </svg>
                  <div class="show-hide" @click.prevent="show('active5')">
                    <img v-if="active5" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                    <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
                  </div>
                </div>
                <div>
                  <vue-feather
                      @mouseover="showPopover7=true" @mouseleave="showPopover7=false"
                      class="status_toggle middle sidebar-toggle"
                      type="help-circle"
                  ></vue-feather>

                  <div v-show="showPopover7" class="popover">
                    Средства доступные для покупок и переводов.
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-3 div-video">
            <div class="card card-body w-100">
              <div class="card-header card-no-border text-left mt-5">
                <h5>Инструменты для бизнеса</h5>
              </div>
              <div class="card-body pt-0">
                <div class="embed-responsive embed-responsive-16by9">
                  <video class="p-3" controls controlsList="nodownload" style="height: 100%; width: 100%;"
                         preload="none" poster="../../assets/images/video/de7efef480b19794da214f40b0f7f359.png">
                    <source src="../../assets/video/video.mp4" data-fluid-hd=""
                    />
                  </video>
                </div>
              </div>
              <div class="wrapper-btn wrapper-btn-video my-5">
                <a
                    href="../../../../my/files/profile/listovka.pdf"
                    target="_blank"
                    class="btn btn-primary pointer-cursor mt-2"
                >Скачать листовку</a>
                <a
                    href="../../../../my/files/profile/Презентация.pptx"
                    target="_blank"
                    class="btn btn-primary pointer-cursor mt-2"
                >Скачать Презентацию</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardDataService from "@/services/DashboardDataService";
import MoneyDataService from "@/services/MoneyDataService";
import alertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import AlertWarning from "@/components/alert-warning.vue";
import AlertInfo from "@/components/alert-info.vue";
import SubscriptionDataService from "@/services/SubscriptionDataService";
import CertificateDataService from "@/services/CertificateDataService";

export default {
  components: {AlertInfo, AlertWarning, AlertSuccess, alertError},
  data() {
    return {
      active1: true,
      active2: true,
      active3: true,
      active4: true,
      active5: true,
      active6: true,
      active7: true,
      showPopover1: false,
      showPopover2: false,
      showPopover3: false,
      showPopover4: false,
      showPopover5: false,
      showPopover6: false,
      showPopover7: false,
      data: {},
      totalValue: Number,
      percentageChange: Number,
      credit: {},
      isSubscription: true,
      isPaymentMoth: true,
      accountActivityEndDate: null,
      profit: 0,
      validate: {
        id: Number,
        code: "",
      },
    };
  },
  methods: {
    show(prop) {
      this[prop] = !this[prop];
    },
    format: (val) =>
        Number(val).toLocaleString()
    ,
  },

  mounted() {
    CertificateDataService.accountActivityEndDate().then((response) => {
      this.accountActivityEndDate = response.data;
    });

    CertificateDataService.isPaymentMoth().then((response) => {
      this.isPaymentMoth = response.data;
    });

    SubscriptionDataService.activate().then((response) => {
      this.isSubscription = response.data;
    });

    MoneyDataService.getCreditTinkoff().then((response) => {
      this.credit = response.data;
    });

    MoneyDataService.getProfit().then((response) => {
      this.profit = response.data;
    });

    MoneyDataService.get().then((response) => {
      this.AvailableWithdrawal = response.data[0];

    });
    DashboardDataService.get()
        .then((response) => {
          this.data = response.data;
          this.totalValue = this.data[4].totalValue;
          this.percentageChange = this.data[4].percentageChange;
        })
        .catch((e) => {
          console.log(e);
        });
  },
};
</script>

<style scoped>
.wrapper-btn-video{
  display:flex;
  justify-content: space-around;
}
.icon-eye {
  width: 30px !important;
  height: 30px !important;
}

.show-hide {
  top: 10px !important;
  right: 15px !important;
}

.sidebar-toggle {
  position: absolute;
  right: 10px;
  top: 70%;
  color: #52526C;
}

.popover {
  position: absolute;
  top: 10px;
  right: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 999;
  opacity: 1;
  transition: all .5s;
}
</style>