.wrapper-grand-coupon{
  position: relative;
  .img-money{
    position: absolute;
    right: 0;
    top: 15px;
  }
  .content-coupon{
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #52526CBF;
      a{
        all: unset;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #52526CBF;
        cursor: pointer;
      }
    }
  }
  .btn-coupon{
    color: white;
    background: #FF8900;
  }
}